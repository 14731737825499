import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { CssClass } from '../../core/constants/constant';
import { ApiService } from '../../core/services/api.service';
import { CommonService } from '../../core/services/common.service';
import { CommonSuccessDialogComponent } from '../success-dialog/success-dialog.component';

@Component({
  selector: 'update-email',
  templateUrl: './update-email.component.html',
  styleUrls: ['./update-email.component.scss']
})
export class UpdateEmailComponent implements OnInit {
  public employeeInfo: any = {};
  public isLoading = false;
  public showStep = 'changeEmail';
  public isUserImpersonated: boolean = false;
  public userId: any;

  constructor(private apiService: ApiService, private route: ActivatedRoute, private commonService: CommonService, public dialogRef: MatDialogRef<UpdateEmailComponent>, public dialog: MatDialog) {
    this.route.params.subscribe(params => {
      this.userId = params['id'];
    });
    this.userId = this.commonService.getUserId();
    if (this.userId != '') {
      this.isUserImpersonated = true;
    } else {
      this.isUserImpersonated = false;
    }
    this.isLoading = true;
    this.userEmail();
  }

  ngOnInit(): void {
  }

  public sendCodeChangeEmail() {
    if (this.isLoading)
      return;
    this.isLoading = true;

    this.apiService.post('b2baccount/send/code/change/email', this.employeeInfo).subscribe((response: any) => {
      if (response.success) {
        this.isLoading = false;
        this.showStep = 'verification';
      }
    }, (error: any) => {
      this.isLoading = false;
    });
  }

  public verifyCodeChangeEmail() {
    if (this.isLoading)
      return;
    this.isLoading = true;

    this.apiService.post('b2baccount/verify/code/change/email', this.employeeInfo).subscribe((response: any) => {
      if (response.success) {
        this.isLoading = false;
        this.dialogRef.close(true);
        this.openSuccessDialog();
      }
    }, (error: any) => {
      this.isLoading = false;
    });
  }

  public userEmail() {
    this.apiService.get('b2baccount/user/email').subscribe((response: any) => {
      if (response.success) {
        this.employeeInfo.currentEmail = response.data;
        this.isLoading = false;
      }
    }, (error: any) => {
      
      this.isLoading = false;
    });
  }

  openSuccessDialog() {
    this.dialog.open(CommonSuccessDialogComponent, {
      panelClass: CssClass.smallScreenClass,
      backdropClass: CssClass.backDropClass,
      data: { title: 'Success! Your email has been changed.', body: 'We have successfully changed your email address.'}
    });
  }
}
