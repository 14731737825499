<div class="glacier_bgcolor medium-drop" mat-dialog-title>
  <div>
    <img src="./app/assets/icons/wordmark.svg" />
    <p class="midgray_color">COBRA</p>
  </div>
  <img src="./app/assets/icons/close-icon.svg" alt="Close" mat-dialog-close class="cursor-pointer" />
</div>

<div class="margin__top" *ngIf="isLoading"><loader></loader></div>
<div mat-dialog-content>
  <div class="mdc-dialog__container-inner main__container">
    <div class="main__heading">Change Email</div>
    <div *ngIf="!isLoading && showStep=='changeEmail'">
      <div class="sub__heading">
        <img src="./app/assets/icons/bottom_arrow_icon.svg" />
        <span class="ml-725 royal_color">Step 1 of 2</span>
      </div>
      <div class="main__dialog">
        <div class="dialog__heading">
          <div class="mb-8">1.</div>
          <div class="mb-16">Change Email</div>
        </div>

        <div class="field-container">

          <form #myForm="ngForm">

            <div class="flex__container">
              <div class="form-field">
                <div class="field__heading mb_12">Current Email</div>
                <div class="field__content">{{employeeInfo.currentEmail}}</div>
              </div>
            </div>

            <div class="form-field">
              <div class="field__heading"><span class="crimson_color">*</span>Enter New Email</div>
              <mat-form-field  class="set_width" hideRequiredMarker appearance="outline">
                <input type="text" pattern="^[a-zA-Z0-9.,'~`_%+-]+@[a-zA-Z0-9.,'~`_%+-]+\.[a-zA-Z]{2,5}" placeholder="What’s your email address?" matInput required name="NewEmail" [(ngModel)]="employeeInfo.newEmail" #NewEmail="ngModel">
                <mat-error>Enter valid Email.</mat-error>
              </mat-form-field>
            </div>

            <div class="form-field">
              <div class="field__heading"><span class="crimson_color">*</span>Confirm New Email</div>
              <mat-form-field  class="set_width" hideRequiredMarker appearance="outline">
                <input type="text" pattern={{NewEmail.value}} placeholder="Emails must match." matInput required name="ConfirmNewEmail" [(ngModel)]="employeeInfo.confirmNewEmail" #ConfirmNewEmail="ngModel">
                <mat-error>Email must match.</mat-error>
              </mat-form-field>
            </div>

            <div class="flex__container">
              <div class="form-field">
                <div class="field__content">In order to complete the process, you must verify your new email. We’ll send you a verification code to your inbox.</div>
              </div>
            </div>

          </form>

        </div>
      </div>

      <div class="button__container">
        <button class="primary-button button_size" [disabled]="!myForm.form.valid || isUserImpersonated" (click)="sendCodeChangeEmail()">Send Code</button>
      </div>
    </div>

    <div  *ngIf="!isLoading && showStep=='verification'">
      <div class="sub__heading">
        <img src="./app/assets/icons/bottom_arrow_icon.svg" />
        <span class="ml-725 royal_color">Step 2 of 2</span>
      </div>
      <div class="main__dialog">
        <div class="dialog__heading">
          <div>2.</div>
          <div class="mt">Verification</div>
        </div>

        <div class="field-container">

          <form #verificationForm="ngForm">

            <div class="flex__container">
              <div class="form-field">
                <div class="field__heading">New Email Address</div>
                <div class="field__content">{{employeeInfo.newEmail}}</div>
              </div>
            </div>

            <div class="flex__container">
              <div class="form-field">
                <div class="field__content">In order to complete the process, you must verify your new email. We’ll send you a verification code to your inbox.</div>
              </div>
            </div>

            <div class="form-field">
              <div class="field__heading"><span class="crimson_color">*</span>Verification Code</div>
              <mat-form-field  class="set_width" hideRequiredMarker appearance="outline">
                <input type="text" placeholder="Enter the code sent to the above email address." matInput required name="VerificationCode" [(ngModel)]="employeeInfo.verificationCode" #VerificationCode="ngModel">
                <mat-error>Enter valid Verification Code.</mat-error>
              </mat-form-field>
            </div>

          </form>

        </div>
      </div>

      <div class="button__container space_between">
        <div class="sub__heading cursor-pointer" (click)="sendCodeChangeEmail()">
          <img src="./app/assets/icons/mail.svg" />
          <span class="ml-725 royal_color">Send New Code</span>
        </div>
        <button class="primary-button button_size" [disabled]="!verificationForm.form.valid || isUserImpersonated" (click)="verifyCodeChangeEmail()">Verify & Complete</button>
      </div>
    </div>
  </div>
</div>
